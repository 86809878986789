import { ref } from 'vue'
import { firestore } from '@/services/firebase.ts'

export function useDeleteDocument() {
  const documentId = ref('')
  const disableConfirmButton = ref(false)
  const displayConfirmDelete = ref(false)

  function deleteDocument(id: string) {
    displayConfirmDelete.value = true
    documentId.value = id
  }

  function confirmDeleteDocument(collection: string) {
    disableConfirmButton.value = true
    return firestore.collection(collection).doc(documentId.value).delete()
  }

  return {
    documentId,
    disableConfirmButton,
    displayConfirmDelete,
    deleteDocument,
    confirmDeleteDocument
  }
}
